import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const requiredText = "This field is required";

export const schema = yupResolver(
  yup.object().shape({
    id: yup.string().trim().required(requiredText),
    name: yup.string().trim().required(requiredText),
    country: yup.string().trim().required(requiredText),
    countryISO: yup.string().trim().required(requiredText),
    state: yup.string().trim().oneOf(["OPEN", "CLOSED"]).required(requiredText),
    //polygonFileName: yup.string().trim().required(requiredText),
    timeZone: yup.string().trim().required(requiredText),

    /*credentials: yup
      .object()
      .shape({
        domain: yup.string().trim().required(requiredText),
        apiKey: yup.string().trim().required(requiredText),
        password: yup.string().trim().required(requiredText),
        storefrontAccessToken: yup.string().trim().required(requiredText),
      })
      .required(requiredText),*/

    /*intercom: yup.object().shape({
      androidApiKey: yup.string().trim().required(requiredText),
      androidId: yup.string().trim().required(requiredText),
      iosApiKey: yup.string().trim().required(requiredText),
      iosId: yup.string().trim().required(requiredText),
    }),*/

    address: yup.string().trim().required(requiredText),
    coordinates: yup.object().shape({
      lat: yup.string().trim().required(requiredText),
      lng: yup.string().trim().required(requiredText),
    }),

    configuration: yup.object().shape({
      orderMinAmount: yup.string().trim().required(requiredText),
      iconPath: yup.string().trim().required(requiredText),
    }),

    etaValue: yup.object().shape({
      id: yup.string().trim().required(requiredText),
    }),
    etaReason: yup.object().shape({
      id: yup.string().trim().required(requiredText),
    }),

    triggers: yup.array().of(
      yup.object().shape({
        type: yup.string().trim().required(requiredText),
        value: yup.string().trim().required(requiredText),
      })
    ),

    properties: yup.array().of(
      yup.object().shape({
        name: yup.string().trim().required(requiredText),
        value: yup.mixed().when("type", validateTypes),
        type: yup.string().required(requiredText),
      })
    ),

    deliveryProperties: yup.array().of(
      yup.object().shape({
        name: yup.string().trim().required(requiredText),
        value: yup.mixed().when("type", validateTypes),
        type: yup.string().required(requiredText),
      })
    ),
  })
);

export const delivery_area_schema = yupResolver(
  yup.object().shape({
    })
);

function validateTypes(type) {
  if (type === "URL") {
    return yup.string().trim().url("This must be a URL");
  }

  if (type === "INT") {
    return yup.number().integer("This must be a integer");
  }

  if (type === "FLOAT") {
    return yup
      .number()
      .typeError("This must be a decimal")
      .test("isFloat", "This must be a decimal value", (number) => {
        return (
          typeof number == "number" &&
          !isNaN(number) &&
          !Number.isInteger(number)
        );
      });
  }

  if (type === "BOOLEAN") {
    return yup.boolean().typeError("This must be a boolean");
  }

  if (type === "COMMA_SEPERATED_INT") {
    return yup
      .string()
      .trim()
      .matches(/^[0-9, /,]+$/, "Please enter int sepatated by comma");
  }

  return yup.string();
}
