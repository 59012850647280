import { useEffect } from "react";
import {
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useFormContext, useFieldArray } from "react-hook-form";

import {
  StyledSectionContainerGrid,
  StyledSectionInputGroupGrid,
  StyledSectionAppendableContainerGrid,
} from "./styles";

import TextField from "../../mui-components/TextField";
import Select from "../../mui-components/Select";

export const TriggerSection = ({ isDisabled }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: "triggers",
  });
  const hasMultipleFields = fields.length > 1;

  useEffect(() => {
    if (!fields.length) {
      append({ type: "", value: "" });
    }
  }, []);

  return (
    <>
      <StyledSectionContainerGrid container spacing={4}>
        <StyledSectionInputGroupGrid item xs={6}>
          <Typography variant="h6">MFC Opening Hours</Typography>
        </StyledSectionInputGroupGrid>
        <StyledSectionInputGroupGrid item xs={6}>
          <Button
            variant="outlined"
            color="success"
            disableRipple
            onClick={() => append({ type: "", value: "" })}
            disabled={isDisabled}
          >
            Add
          </Button>
        </StyledSectionInputGroupGrid>
      </StyledSectionContainerGrid>
      {fields.map((field, index) => (
        <StyledSectionAppendableContainerGrid
          key={field.id}
          container
          spacing={4}
        >
          <StyledSectionInputGroupGrid item xs={4}>
            <FormControl variant="standard">
              <InputLabel id="state-select-label">Type</InputLabel>
              <Select
                disabled={isDisabled}
                name={`triggers.${index}.type`}
                labelId="state-select-label"
                label="Type"
              >
                <MenuItem value={"OPEN"}>OPEN</MenuItem>
                <MenuItem value={"CLOSE"}>CLOSED</MenuItem>
              </Select>
            </FormControl>
          </StyledSectionInputGroupGrid>
          <StyledSectionInputGroupGrid item xs={hasMultipleFields ? 6 : 8}>
            <TextField
              disabled={isDisabled}
              name={`triggers.${index}.value`}
              label="Trigger Value"
              variant="standard"
            />
          </StyledSectionInputGroupGrid>
          {hasMultipleFields && (
            <StyledSectionInputGroupGrid item xs={2}>
              <Button
                disabled={isDisabled}
                variant="outlined"
                color="error"
                disableRipple
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </StyledSectionInputGroupGrid>
          )}
        </StyledSectionAppendableContainerGrid>
      ))}
    </>
  );
};
